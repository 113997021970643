<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-0">
        <v-card>
          <v-toolbar>
            <v-toolbar-title> Company Commissions </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="info" v-bind="attrs" v-on="on" class="mr-2">
                  <v-icon class="mr-2"> mdi-calendar-month </v-icon>Year -
                  {{ selectedYear }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="year in last5Years"
                  :key="year"
                  @click="changeYear(year)"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" v-show="selectedYear == year">
                      mdi-circle-small
                    </v-icon>
                    {{ year }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="info" v-bind="attrs" v-on="on" class="mr-2">
                  <v-icon class="mr-2"> mdi-calendar-star </v-icon>Quarter -
                  {{ selectedQuarter }}
                </v-btn>
              </template>
              <v-list>
                <template v-for="q in 4">
                  <v-list-item @click="changeQuarter(q)">
                    <v-list-item-title>
                      <v-icon class="mr-2" v-show="selectedQuarter == q">
                        mdi-circle-small
                      </v-icon>
                      Q{{ q }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <v-btn
              color="success"
              @click="showFinaliseQuarter"
              :loading="isLoadingShowFinaliseQuarter"
            >
              <v-icon class="mr-2"> mdi-flag-checkered </v-icon>
              Finalise this Quarter
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card>
          <v-card-text class="pb-0 pt-0">
            <v-row>
              <v-col>
                <v-icon color="warning" class="mr-2">
                  mdi-help-circle-outline </v-icon
                >To be approved
              </v-col>
              <v-col class="text-right">
                <v-btn
                  small
                  :disabled="toBeApprovedPeoplePairs.length == 0"
                  @click="
                    isShowingUserTotals = true;
                    currentUserTotals = toBeApprovedPeoplePairs;
                  "
                >
                  <v-icon class="mr-2"> mdi-magnify </v-icon>
                  Totals
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="text-center">
            <h2 class="text--lighten-3 orange--text">
              £<trev-number-ticker
                :duration="numberTickerDuration"
                :number="toBeApprovedSum"
              ></trev-number-ticker>
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pt-0">
        <v-card>
          <v-card-text class="pb-0 pt-0">
            <v-row>
              <v-col>
                <v-icon color="success" class="mr-2">
                  mdi-check-circle-outline </v-icon
                >Approved
              </v-col>
              <v-col class="text-right">
                <v-btn
                  small
                  :disabled="approvedPeoplePairs.length == 0"
                  @click="
                    isShowingUserTotals = true;
                    currentUserTotals = approvedPeoplePairs;
                  "
                >
                  <v-icon class="mr-2"> mdi-magnify </v-icon>
                  Totals
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="text-center">
            <h2 class="text--lighten-3 green--text">
              £<trev-number-ticker
                :duration="numberTickerDuration"
                :number="approvedSum"
              ></trev-number-ticker>
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pt-0">
        <v-card>
          <v-card-text class="pb-0 pt-0">
            <v-row>
              <v-col>
                <v-icon color="danger" class="mr-2">
                  mdi-help-circle-outline </v-icon
                >Queried
              </v-col>
              <v-col class="text-right">
                <v-btn
                  small
                  :disabled="deniedPeoplePairs.length == 0"
                  @click="
                    isShowingUserTotals = true;
                    currentUserTotals = deniedPeoplePairs;
                  "
                >
                  <v-icon class="mr-2"> mdi-magnify </v-icon>
                  Totals
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="text-center">
            <h2 class="text--lighten-3 red--text">
              £<trev-number-ticker
                :number="deniedSum"
                :duration="numberTickerDuration"
              ></trev-number-ticker>
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <trev-cool-table
          ref="table"
          :courier="$courier.Commissions"
          :headers="getHeaders"
          :additionalQueryStrings="[
            { key: 'quarterNumber', value: selectedQuarter },
            { key: 'year', value: selectedYear },
            { key: 'statusFilter', value: selectedStatus },
          ]"
        >
          <template #title>
            <v-icon class="mr-2"> mdi-cash-multiple </v-icon>
            Commissions -
            <v-btn
              small
              :disabled="status == selectedStatus"
              class="mr-2"
              color="info"
              v-for="(status, index) in allStatuses"
              :key="index"
              @click="changeTableStatus(status)"
            >
              <v-icon class="mr-2" v-show="status == selectedStatus">
                mdi-circle-small
              </v-icon>
              {{ status }}
            </v-btn>
          </template>
          <template #item.addedBy="{ item }">
            <v-chip small>
              <v-icon class="mr-2"> mdi-account-outline </v-icon>
              {{ item.addedBy }}
            </v-chip>
          </template>
          <template #item.rate="{ item }">
            <v-chip color="success" small> {{ item.rate }}% </v-chip>
          </template>
          <template #item.invoiceNumber="{ item }">
            <v-chip label color="info" small>
              {{ item.invoiceNumber }}
            </v-chip>
          </template>
          <template #item.zohoDealName="{ item }">
            <v-btn
              small
              v-if="item.zohoDealName && item.zohoDealId"
              :href="
                'https://crm.zoho.eu/crm/org20068100338/tab/Potentials/' +
                item.zohoDealId
              "
            >
              <v-icon class="mr-2"> mdi-open-in-new </v-icon>
              {{ item.zohoDealName }}
            </v-btn>
          </template>
          <template #item.invoiceDate="{ item }">
            {{ new Date(item.invoiceDate).toLocaleDateString() }}
          </template>
          <template #item.commissionAmount="{ item }">
            <v-chip color="success" label small>
              £{{ getCommission(item) }}
            </v-chip>
          </template>
          <template #item.actionCommission="{ item }">
            <v-btn color="purple" small @click="showActionCommission(item)">
              <v-icon class="mr-2"> mdi-delta </v-icon>
              Approve / Deny
            </v-btn>
          </template>
          <template #item.actions="{ item }">
            <v-btn
              small
              color="info"
              :to="{
                name: 'View Commission',
                params: { commissionId: item.id },
              }"
            >
              <v-icon class="mr-2"> mdi-magnify </v-icon>
              View
            </v-btn>
            <v-btn
              small
              class="ml-2"
              v-if="item.invoiceId"
              target="blank"
              :href="
                'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' +
                item.invoiceId
              "
            >
              <v-icon class="mr-2"> mdi-open-in-new </v-icon>
              Open in Xero
            </v-btn>
          </template>
        </trev-cool-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showFinaliseQuarterDialog"
      max-width="60vh"
      @click:outside="cancelShowFinaliseQuarter"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2"> mdi-flag-checkered </v-icon>
            Finalise Quarterly Commissions
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <h5 class="mt-4">
            Are you sure you want to
            <span class="danger--text">finalise</span> this quarter?
          </h5>
          <h6>
            This will <span class="info--text">lock</span>
            {{ finalisedCommissionCount }} claims
            <span v-show="movedCommissionsToNextQuarter > 0">
              , and move {{ movedCommissionsToNextQuarter }} to next quarter.
            </span>
          </h6>
        </v-card-text>
        <v-card-text>
          <v-btn
            class="mr-2"
            color="success"
            @click="finaliseQuarter"
            :loading="isLoadingFinaliseQuarter"
          >
            <v-icon class="mr-2"> mdi-check </v-icon>
            Finalise
          </v-btn>
          <v-btn text @click="cancelShowFinaliseQuarter"> Cancel </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <trev-commission-action-dialog
      @actioned="refreshAll"
      :commission="selectedCommission"
      :showDialog.sync="showAproveCommissionDialog"
    ></trev-commission-action-dialog>
    <v-dialog max-width="60vh" v-model="isShowingUserTotals">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2"> mdi-finance </v-icon>
            User Commission Totals
          </v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item v-for="(user, index) in currentUserTotals" :key="index">
            <v-list-item-icon>
              <v-icon large> mdi-account-circle </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-row>
                  <v-col>
                    {{ user.user }}
                  </v-col>
                  <v-col class="text-right">
                    £{{ user.totalCommission }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TrevCommissionActionDialog from "../../../../components/commission/trev-commission-action.dialog.vue";
import TrevCoolTable from "../../../../components/tables/trev-cool-table.vue";
import trevNumberTicker from "../../../../components/ui/trev-number-ticker.vue";
import * as GeneralUtilities from "../../../../utilities/GeneralUtilities";

export default {
  components: { trevNumberTicker, TrevCoolTable, TrevCommissionActionDialog },
  watch: {
    selectedYear(newValue, oldValue) {
      this.$nextTick(() => {
        this.getCommissionForQuarter();

        this.$refs.table.getData();
      });
    },
    selectedQuarter(newValue, oldValue) {
      this.$nextTick(() => {
        this.getCommissionForQuarter();

        this.$refs.table.getData();
      });
    },
  },
  computed: {
    getHeaders() {
      if (this.selectedStatus != "Awaiting Approval") {
        return this.headers.filter((x) => x.value != "actionCommission");
      } else {
        return this.headers;
      }
    },
    last5Years() {
      var allYears = [];
      var currentDate = new Date();
      for (
        var i = currentDate.getFullYear() - 3;
        i <= currentDate.getFullYear();
        i++
      ) {
        allYears.push(i);
      }

      return allYears;
    },
  },
  created() {
    if (this.$store.getters.getCommissionYear > 0) {
      this.selectedYear = this.$store.getters.getCommissionYear;
    } else {
      this.selectedYear = new Date().getFullYear();
    }

    if (this.$store.getters.getCommissionQuarter > 0) {
      this.selectedQuarter = this.$store.getters.getCommissionQuarter;
    } else {
      this.selectedQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    }

    this.getCommissionForQuarter();
  },
  methods: {
    changeYear(year) {
      this.selectedYear = year;
      this.$store.commit("setCommissionYear", year);
    },
    changeQuarter(quarter) {
      this.selectedQuarter = quarter;
      this.$store.commit("setCommissionQuarter", quarter);
    },
    showActionCommission(commission) {
      this.showAproveCommissionDialog = true;
      this.selectedCommission = commission;
    },
    finaliseQuarter() {
      this.isLoadingFinaliseQuarter = true;

      this.$courier.Commissions.finaliseQuarter(
        this.selectedYear,
        this.selectedQuarter
      ).then(async () => {
        //reload everything /
        await this.getCommissionForQuarter();
        this.$refs.table.getData();

        this.isLoadingFinaliseQuarter = false;
        this.showFinaliseQuarterDialog = false;
      });
    },
    cancelShowFinaliseQuarter() {
      this.finalisedCommissionCount = 0;
      this.movedCommissionsToNextQuarter = 0;
      this.showFinaliseQuarterDialog = false;
    },
    async showFinaliseQuarter() {
      this.isLoadingShowFinaliseQuarter = true;
      var approvedCommissionCount = await this.$courier.Commissions.getData(
        1,
        0,
        "",
        null,
        null,
        [
          { key: "year", value: this.selectedYear },
          { key: "quarterNumber", value: this.selectedQuarter },
          { key: "statusFilter", value: "Approved" },
        ]
      );

      this.finalisedCommissionCount = approvedCommissionCount.totalCount;

      var movedCommissionCount = await this.$courier.Commissions.getData(
        1,
        0,
        "",
        null,
        null,
        [
          { key: "year", value: this.selectedYear },
          { key: "quarterNumber", value: this.selectedQuarter },
          { key: "statusFilter", value: "To Be Approved" },
        ]
      );

      this.movedCommissionsToNextQuarter = movedCommissionCount.totalCount;

      this.isLoadingShowFinaliseQuarter = false;

      this.showFinaliseQuarterDialog = true;
    },
    getCommission(commissionObj) {
      return GeneralUtilities.getCommissionFromObj(commissionObj);
    },
    changeTableStatus(statusName) {
      this.selectedStatus = statusName;
      this.$nextTick(() => {
        this.$refs.table.getData();
      });
    },
    refreshAll() {
      this.$refs.table.getData();
      this.getCommissionForQuarter();
    },
    async getCommissionForQuarter() {
      this.$courier.Commissions.getCommissionDetailsByQuarter(
        this.selectedQuarter,
        this.selectedYear,
        "Approved"
      ).then((payload) => {
        this.approvedSum = payload.totalCommission;
        this.approvedPeoplePairs = payload.userCommissions;
      });

      this.$courier.Commissions.getCommissionDetailsByQuarter(
        this.selectedQuarter,
        this.selectedYear,
        "Queried"
      ).then((payload) => {
        this.deniedSum = payload.totalCommission;
        this.deniedPeoplePairs = payload.userCommissions;
      });

      this.$courier.Commissions.getCommissionDetailsByQuarter(
        this.selectedQuarter,
        this.selectedYear,
        "Awaiting Approval"
      ).then((payload) => {
        this.toBeApprovedSum = payload.totalCommission;
        this.toBeApprovedPeoplePairs = payload.userCommissions;
      });
    },
    getCommissionSumForStatus(statusName, commissionArray) {
      var total = 0;

      for (const commission of commissionArray.filter(
        (x) => x.status === statusName
      )) {
        total += GeneralUtilities.getCommissionFromObj(commission);
      }

      return total;
    },
  },
  data() {
    return {
      isShowingUserTotals: false,
      currentUserTotals: [],
      isLoadingShowFinaliseQuarter: false,
      isLoadingFinaliseQuarter: false,
      finalisedCommissionCount: 0,
      showFinaliseQuarterDialog: false,
      movedCommissionsToNextQuarter: 0,
      selectedCommission: {},
      totalCountTimeOutId: "",
      selectedYear: 2023,
      selectedQuarter: 1,
      toBeApprovedSum: 0,
      approvedSum: 0,
      deniedSum: 0,
      toBeApprovedPeoplePairs: [],
      approvedPeoplePairs: [],
      deniedPeoplePairs: [],
      showDenyCommissionDialog: false,
      numberTickerDuration: 1,
      selectedStatus: "Awaiting Approval",
      showAproveCommissionDialog: false,
      allStatuses: ["Awaiting Approval", "Approved", "Queried", "Finalised"],
      headers: [
        {
          text: "User",
          value: "addedBy",
        },
        {
          text: "Invoice Number",
          value: "invoiceNumber",
        },
        {
          text: "Invoice Date",
          value: "invoiceDate",
        },
        {
          text: "Zoho Deal",
          value: "zohoDealName",
        },
        {
          text: "Rate",
          value: "rate",
        },
        {
          text: "Commission Amount",
          value: "commissionAmount",
        },
        {
          text: "Action Commission",
          value: "actionCommission",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style></style>
