<template>
    <div>
        <v-dialog v-model="innerShowDialog" max-width="60vh">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        Action Commission
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-chip label>
                                £{{ getCommission(commission) }}
                            </v-chip>
                        </v-col>
                        <v-col>
                            <v-chip label>
                                #{{ commission.invoiceNumber }}
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col class="text-center">
                            <v-btn color="success" class="mr-2" @click="approveCommission">
                                <v-icon class="mr-2">
                                    mdi-check-circle-outline
                                </v-icon>
                                Approve
                            </v-btn>
                        </v-col>
                        <v-col class="text-center">
                            <v-btn color="danger" @click="denyCommission">
                                <v-icon class="mr-2">
                                    mdi-cancel
                                </v-icon>
                                Deny
                            </v-btn>
                        </v-col>
                        <v-col class="text-center">
                            <v-btn color="warning" @click="voidCommission">
                                <v-icon class="mr-2">
                                    mdi-close-circle-outline
                                </v-icon>
                                Void
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDenyVoidCommissionNoteDialog" max-width="60vh" @click:outside="cancelDenyVoidDialog">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        Please provide a note for this {{ denyVoidSelection }} commission
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-textarea label="Notes" v-model="denyVoidNote"></v-textarea>
                </v-card-text>
                <v-card-text>
                    <v-btn color="primary" @click="addCommissionNote">
                        <v-icon class="mr-2">
                            mdi-floppy
                        </v-icon>
                        Save Note
                    </v-btn>
                    <v-btn class="ml-2" text @click="cancelDenyVoidDialog">
                        Cancel
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as GeneralUtilities from '../../utilities/GeneralUtilities';

export default {
    props: {
        commission: {
            type: Object,
            default: () => { }
        },
        showDialog: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        showDialog(newValue, oldValue) {
            this.innerShowDialog = this.showDialog;
        },
        innerShowDialog(newValue, oldValue) {
            this.$emit('update:showDialog', this.innerShowDialog);
        }
    },
    methods: {
        getCommission(commissionObj) {
            return GeneralUtilities.getCommissionFromObj(commissionObj);
        },
        addCommissionNote() {
            this.$courier.Commissions.withId(this.commission.id).Notes.add({
                note: this.denyVoidNote
            }).then(() => {
                this.$emit('added-note');
                this.cancelDenyVoidDialog();
            });
        },
        approveCommission() {
            this.$courier.Commissions.approveCommission(this.commission.id).then(() => {
                this.$emit('actioned', 'approved');
                this.innerShowDialog = false;
            });
        },
        denyCommission() {
            this.denyVoidSelection = 'queried';
            this.$courier.Commissions.denyCommission(this.commission.id).then(() => {
                this.innerShowDialog = false;
                this.$emit('actioned', 'denied');
                this.showDenyVoidCommissionNoteDialog = true;
            });
        },
        voidCommission() {
            this.denyVoidSelection = 'void';
            this.$courier.Commissions.voidCommission(this.commission.id).then(() => {
                this.innerShowDialog = false;
                this.$emit('actioned', 'void');
                this.showDenyVoidCommissionNoteDialog = true;
            });
        },
        cancelDenyVoidDialog() {
            this.showDenyVoidCommissionNoteDialog = false;
            this.denyVoidSelection = '';
            this.denyVoidNote = '';
        },
    },
    data() {
        return {
            innerShowDialog: false,
            denyVoidSelection: '',
            denyVoidNote: '',
            showDenyVoidCommissionNoteDialog: false,
        }
    },
}
</script>

<style></style>